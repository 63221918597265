import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "login",
      component: () => import("@/views/pages/Authentication/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    // {
    //   path: '/forgotpassword',
    //   name: 'ForgotPassword',
    //   component: () => import('@/views/pages/Authentication/ForgotPassword.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '/resetpassword',
    //   name: 'ResetPassword',
    //   component: () => import('@/views/pages/Authentication/ResetPassword.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/pages/Home/Home.vue"),
      meta: {
        pageTitle: "Dashboard",
        // breadcrumb: [
        //   {
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: "/masters",
      name: "masters",
      component: () => import("@/views/pages/Master/MasterList.vue"),
      meta: {
        pageTitle: "Masters",
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/movies",
      name: "movies",
      component: () => import("@/views/pages/Movie/MovieList.vue"),
      meta: {
        pageTitle: "Movies",
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/movies/form/:movie_id",
      name: "movies-form",
      component: () => import("@/views/pages/Movie/MovieForm.vue"),
      meta: {
        pageTitle: "Movies",
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
          {
            text: "Form",
            active: true,
          },
        ],
      },
    },
    {
      path: "/movies/details/:movie_id",
      name: "movies-details",
      component: () => import("@/views/pages/Movie/MovieDetails.vue"),
      meta: {
        pageTitle: "Movies",
        breadcrumb: [
          {
            text: "Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/contracts",
      name: "contracts",
      component: () => import("@/views/pages/Contracts/ContractsList.vue"),
      meta: {
        pageTitle: "Contracts",
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },

    {
      path: "/milestones",
      name: "milestones",
      component: () => import("@/views/pages/Contracts/MilestoneBumpers.vue"),
      meta: {
        pageTitle: "Milestones & Bumpers",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
    {
      path: "/bumpers",
      name: "bumpers",
      component: () =>
        import("@/views/pages/Contracts/Bumpers/BumpersList.vue"),
      meta: {
        pageTitle: "Bumpers",
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/contracts/form/:contract_id",
      name: "contracts-form",
      component: () => import("@/views/pages/Contracts/ContractsForm.vue"),
      meta: {
        pageTitle: "Contract",
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
          {
            text: "Form",
            active: true,
          },
        ],
      },
    },
    {
      path: "/contracts/details/:contract_id",
      name: "contracts-details",
      component: () => import("@/views/pages/Contracts/ContractsDetails.vue"),
      meta: {
        pageTitle: "Contract",
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
          {
            text: "Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/contacts",
      name: "contacts",
      component: () => import("@/views/pages/Customer/CustomerList.vue"),
      meta: {
        pageTitle: "Customers",
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/contacts/form/:contact_id",
      name: "contacts-form",
      component: () => import("@/views/pages/Customer/CustomerForm.vue"),
      meta: {
        pageTitle: "Customer",
        breadcrumb: [
          // {
          //   text: "List",
          //   active: true,
          // },
          {
            text: "Form",
            active: true,
          },
        ],
      },
    },
    {
      path: "/contacts/overview/:contact_id",
      name: "contacts-overview",
      component: () => import("@/views/pages/Customer/CustomerOverview.vue"),
      meta: {
        pageTitle: "Customer",
        breadcrumb: [
          // {
          //   text: "List",
          //   active: true,
          // },
          {
            text: "Overview",
            active: true,
          },
        ],
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },

    //Revenue Routes Starts here
    // {
    //   path: "/addrevenue",
    //   name: "AddRevenue",
    //   component: () => import("@/views/pages/Revenue/AddRevenue.vue"),
    //   meta: {
    //     pageTitle: "Revenue & Expense",
    //   },
    // },
    {
      path: "/addrevenueMain",
      name: "AddRevenueMain",
      component: () => import("@/views/pages/Revenue/AddRevenueMain.vue"),
      meta: {
        pageTitle: "Revenues,Expenses & Invoices",
        breadcrumb: [
          {
            active: true,
          },
        ],
      },
    },
    {
      path: "/additional-cost",
      name: "AdditionalCost ",
      component: () =>
        import(
          "@/views/pages/Revenue/RevenuePages/ViewAdditionalCostRevenue.vue"
        ),
      meta: {
        pageTitle: "Additional Cost ",
      },
    },
    // {
    //   path: "/addmanualrevenue",
    //   name: "AddManualRevenue",
    //   component: () => import("@/views/pages/Revenue/AddManualRevenue.vue"),
    //   meta: {
    //     pageTitle: "Manual Revenue/Expense",
    //   },
    // },

    // {
    //   path: "/invoiceform",
    //   name: "InvoiceForm",
    //   component: () => import("@/views/pages/Revenue/InvoiceForm.vue"),
    //   meta: {
    //     pageTitle: "Revenue Invoicing",
    //   },
    // },

    // {
    //   path: "/revenueinvoice",
    //   name: "RevenueInvoice",
    //   component: () => import("@/views/pages/Revenue/RevenueInvoice.vue"),
    //   meta: {
    //     pageTitle: "Revenue Invoice",
    //   },
    // },
    // {
    //   path: "/generateinvoice",
    //   name: "GenerateInvoice",
    //   component: () => import("@/views/pages/Revenue/GenerateInvoice.vue"),
    //   meta: {
    //     pageTitle: "Invoice",
    //   },
    // },

    {
      path: "/previewinvoice/:revenue_invoice_id",
      name: "PreviewInvoice",
      component: () => import("@/views/pages/Revenue/PreviewInvoice.vue"),
      meta: {
        pageTitle: "Invoice Preview",
      },
    },

    //Revenue Routes Ends here

    //Royalty routes
    {
      path: "/generateroyalty",
      name: "GenerateRoyalty",
      component: () =>
        import("@/views/pages/Royalty/GenerateRoyaltyReport.vue"),
      meta: {
        pageTitle: "Royalty Report",
        breadcrumb: [
          {
            active: true,
          },
        ],
      },
    },
    {
      path: "/generateroyaltyterritory",
      name: "GenerateRoyaltyTerritory",
      component: () =>
        import("@/views/pages/Royalty/GenerateRoyaltyReportTerritory.vue"),
      meta: {
        pageTitle: "Royalty Report With Territoy",
        breadcrumb: [
          {
            active: true,
          },
        ],
      },
    },
    //
    {
      path: "/previewroyalty/:royalty_report_id",
      name: "PreviewRoyaltyReport",
      component: () => import("@/views/pages/Royalty/PreviewRoyaltyReport.vue"),
      meta: {
        pageTitle: "Royalty Report Preview",
        breadcrumb: [
          {
            active: true,
          },
        ],
      },
    },

    //BookKeeping
    {
      path: "/bookkeeping",
      name: "Bookkeeping",
      component: () =>
        import("@/views/pages/Bookkeeping/BookKeepingReports.vue"),
      meta: {
        pageTitle: "Bookkeeping Reports",
        breadcrumb: [
          {
            active: true,
          },
        ],
      },
    },

    //end here royalty routes

    //Revenue Expense
    // {
    //   path: "/addrevenueexpense",
    //   name: "AddRevenueExpense",
    //   component: () =>
    //     import("@/views/pages/RevenueExpense/AddRevenueExpense.vue"),
    //   meta: {
    //     pageTitle: "Expense",
    //   },
    // },
    // {
    //   path: "/viewrevenueexpense",
    //   name: "ViewRevenueExpense",
    //   component: () =>
    //     import("@/views/pages/RevenueExpense/ViewRevenueExpense.vue"),
    //   meta: {
    //     pageTitle: "All Expenses",
    //   },
    // },

    //Ends Revenue Expense

    {
      path: "/budget",
      name: "viewBudget",
      component: () => import("@/views/pages/Budget/ViewBudget.vue"),
      meta: {
        pageTitle: "Budget",
      },
    },
    {
      path: "/budget/add",
      name: "addBudget",
      component: () => import("@/views/pages/Budget/AddBudget.vue"),
      meta: {
        pageTitle: "Add Budget",
      },
    },
    {
      path: "/budget/overview/:id",
      name: "budgetOverview",
      component: () => import("@/views/pages/Budget/BudgetOverview.vue"),
      meta: {
        pageTitle: "Budget Overview",
      },
    },

    {
      path: "/budget-expense/:id",
      name: "viewBudgetExpense",
      component: () =>
        import("@/views/pages/BudgetExpense/ViewBudgetExpense.vue"),
      meta: {
        pageTitle: "Expenses",
      },
    },
    {
      path: "/budget-expense/add/:id",
      name: "addBudgetExpense",
      component: () =>
        import("@/views/pages/BudgetExpense/AddBudgetExpense.vue"),
      meta: {
        pageTitle: "Add Expenses",
      },
    },

    //Reports routes

    {
      path: "/report",
      name: "Report",
      component: () => import("@/views/pages/Report/rightsReport.vue"),
      meta: {
        pageTitle: "Rights Report",
      },
    },
    {
      path: "/report/movieOverview",
      name: "movieOverview",
      component: () => import("@/views/pages/Report/MovieOverview.vue"),
      meta: {
        pageTitle: "Movie Overview",
      },
    },
    {
      path: "/report/royaltyReportOverview",
      name: "royaltyReportOverview",
      component: () => import("@/views/pages/Report/RoyaltyReportOverview.vue"),
      meta: {
        pageTitle: "Royalty Report Overview",
      },
    },

    {
      path: "/report/reportingSalesAvailabilities",
      name: "reportingSalesAvailabilities",
      component: () =>
        import("@/views/pages/Report/reportingSalesAvailabilitiesV2.vue"),
      meta: {
        pageTitle: "Reporting Sales / Availabilities",
      },
    },

    {
      path: "/report/reporting",
      name: "reporting",
      component: () => import("@/views/pages/Report/reporting.vue"),
      meta: {
        pageTitle: "Reports",
      },
    },

    {
      path: "/report/contractOverview",
      name: "contractOverview",
      component: () => import("@/views/pages/Report/ContractOverview.vue"),
      meta: {
        pageTitle: "Contract Overview",
      },
    },

    // Report Builder

    {
      path: "/report-builder-pdf",
      name: "ReportBuilderPdf",
      component: () =>
        import("@/views/pages/ReportBuilder/ReportBuilderPdf.vue"),
      meta: {
        pageTitle: "Report Builder",
      },
    },

    {
      path: "/report-builder-excel",
      name: "ReportBuilderExcel",
      component: () =>
        import("@/views/pages/ReportBuilder/ReportBuilderExcel.vue"),
      meta: {
        pageTitle: "Report Builder",
      },
    },

    // User Management Route
    {
      path: "/user/form/:user_id",
      name: "user-form",
      component: () => import("@/views/pages/UserManagement/User/UserForm.vue"),
      meta: {
        pageTitle: "User",
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
          {
            text: "Form",
            active: true,
          },
        ],
      },
    },

    {
      path: "/user",
      name: "User",
      component: () => import("@/views/pages/UserManagement/User/UserList.vue"),
      meta: {
        pageTitle: "User",
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },

    {
      path: "/role/form/:role_id",
      name: "role-form",
      component: () => import("@/views/pages/UserManagement/Role/RoleForm.vue"),
      meta: {
        pageTitle: "Role",
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
          {
            text: "Form",
            active: true,
          },
        ],
      },
    },

    {
      path: "/role",
      name: "Role",
      component: () => import("@/views/pages/UserManagement/Role/RoleList.vue"),
      meta: {
        pageTitle: "List",
      },
    },

    {
      path: "/organization",
      name: "Organization",
      component: () => import("@/views/pages/Organization/Organization.vue"),
      meta: {
        pageTitle: "Info",
      },
    },

    {
      path: "/notifications",
      name: "Notifications",
      component: () => import("@/views/pages/Notifications/Notifications.vue"),
      meta: {
        pageTitle: "Notifications",
      },
    },

    //Calender

    {
      path: "/calendar",
      name: "calendar",
      component: () => import("@/views/pages/Calendar/Calendar.vue"),
    },

    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
